import React,{useEffect, useState} from 'react'
import { Helmet } from 'react-helmet';
import NavbarH from './Navbar/NavbarH'
import Hero from './TacoHero/Hero';
import Features from './Features/Features';
import Reservation from './Reservation/Reservation';
import Cards from './Cards/Card'
import Socials from './Socials/Social'
import Subscribe from './Subscribe/Subscribe';
import Footer from './Footer/Footer';
import Rights from './Rights/Rights';
import FeaturesTwo from './FeaturesTwo/FeaturesTwo';
import circular from '../Assets/CircularProgressIndicatorend.gif';
import './LandPage.css'

const App = () => {
  const [isLoading, setLoader] = useState(true)
  useEffect(() => {
    const num = Math.floor(Math.random() * 2000)
    setTimeout(() => {
      setLoader(false)
    },num)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className='App'>
        { isLoading ? 
        <div className='background-whito'>
         <img src={circular} className='circular-gif'/>
        </div>
        :
      <>
      <Helmet>
        <title>Lalo's Mexican | Hudson</title>
      </Helmet>
      <NavbarH/>
      <Hero/>
      <Features/>
      <FeaturesTwo/>
      <Reservation/>
      <Cards/>
      <Socials/>
      <Subscribe/>
      <Footer/>
      <Rights/>
      </>
      }
    </div>
  )
}

export default App
import React from 'react'
import './Rights.css'




const Rights = () => {
  return (
    <div className='rightss'>
       <p> @LalosMexicanrestaurant, All rights reserved</p>
    </div>
  )
}

export default Rights
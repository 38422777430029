import React,{useEffect, useState} from 'react'
import { Helmet } from 'react-helmet';
import NavbarM from './Navbar/NavbarM'
import HeroM from './TacoHero/HeroM';
import FeaturesM from './Features/FeaturesM';
import ReservationM from './Reservation/ReservationM';
import Cards from './Cards/Card'
import Socials from './Socials/Social'
import Subscribe from './Subscribe/Subscribe';
import FooterM from './Footer/FooterM';
import Rights from './Rights/Rights';
import FeaturesTwoM from './FeaturesTwo/FeaturesTwoM';
import circular from '../Assets/CircularProgressIndicatorend.gif';
import './LandPage.css'

const App = () => {
  const [isLoading, setLoader] = useState(true)
  useEffect(() => {
    const num = Math.floor(Math.random() * 2000)
    setTimeout(() => {
      setLoader(false)
    },num)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='App'>
       { isLoading ? 
        <div className='background-whito'>
         <img src={circular} className='circular-gif'/>
        </div>
        :
      <>
      <Helmet>
        <title>Lalo's Mexican | Marlborough</title>
      </Helmet>
      <NavbarM/>
      <HeroM/>
      <FeaturesM/>
      <FeaturesTwoM/>
      <ReservationM/>
      <Cards/>
      <Socials/>
      <Subscribe/>
      <FooterM/>
      <Rights/>
      </>
      }
    </div>
  )
}

export default App
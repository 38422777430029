import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../../Assets/lalologo.svg';
import {RiArrowDropDownLine } from "react-icons/ri"
import { GrLocation } from "react-icons/gr"
import { TfiLocationPin } from "react-icons/tfi"
import { IoIosArrowDown } from 'react-icons/io'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { Link } from 'react-router-dom';

function Dropdown (props) {
  return(
    <ul>
      <li>{props.name}</li>
    </ul>
  );
}

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdown, setDropdown] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function handleClick() {
    window.location.reload();
  }

  return (
    
       <nav className={`navbar ${isScrolled ? 'navbar-white' : ''}`}> 
      <div className='navbar-container'>
        <div className='navbar-left'>
          <div>
          <Link to={"/"}><img src={logo} alt="Logo" className={`icon-lft-main ${isScrolled ? 'icon-lft-main-white' : ''}`}/></Link>
          </div>
          <div className='navbar-list-left'>
            {/* <Link style={{ textDecoration:"none"}}><li className='listoo'><a>Menu</a></li></Link> */}
            <Link to={"/marlborough/menu"} style={{ textDecoration:"none"}}><p className={isScrolled ? 'listoo-sc' : 'listoo'} target='_blank'><a>Menu</a></p></Link>
            <Link to="https://www.clover.com/online-ordering/lalos-mexican-restaurant-marlborough" style={{ textDecoration:"none"}} target='_blank'><p className={isScrolled ? 'listoo-sc' : 'listoo'}>Order Online</p></Link>
           <Link to='/marlborough/contact-us'style={{ textDecoration:"none"}}><p className={`quote-btn ${isScrolled ? 'quote-btn-white' : ''}`}>Get Quote</p></Link>
          </div>
        </div>

        <div className='navbar-right'>
          <div>
        <p className='loce'><HiOutlineLocationMarker className='loce-icon'/><a onClick={() => setDropdown(!dropdown)}>Malborough, MA</a><IoIosArrowDown className='loce-arrow' onClick={() => setDropdown(!dropdown)}/></p>
        {/* style={{color: 'orange', fontSize: '50px',}} */}
          </div>
          <div className='dropdown-container'>
            {
              dropdown && 
              <div className='dropdown'>
                <Link to ="/hudson" style={{ textDecoration:"none",marginLeft:"-20px",marginRight:"20px",color:"black"  }} > <Dropdown name={"Hudson"}/> </Link>
                <Link to ="/marlborough" style={{ textDecoration:"none",marginLeft:"-20px",marginRight:"20px",color:"black"  }}><Dropdown name={"Marlborough"} /> </Link>
              </div>
              
            }
          </div>
        </div>
      
      </div>
     
    </nav>
  );
};

export default Navbar;




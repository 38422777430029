import React,{useState, useEffect} from 'react';
import './Card.css'; // import your styles file
import icon_one from "../../Assets/sv_one.svg"
import icon_two from "../../Assets/sv_two.svg"
import icon_three from "../../Assets/sv_three.svg"
import card_border from "../../Assets/border.svg"

import SwiperCore, { Navigation,Pagination } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/swiper-bundle.css'
// import 'swiper/components/navigation/navigation.min.css';
// import 'swiper/components/pagination/pagination.min.css';
SwiperCore.use([Navigation, Pagination]);

function CardSection() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const prevButtonStyle = {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
  };
  const nextButtonStyle = {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  return (
    <div className="card-section">
      {!isMobile &&

      <div className='card-wrap'>

        <div className="card">
          <div>
            <img src={icon_one} alt="Logo 1" className="card-logo" />
            <h2 className="card-heading" >Delicious Food</h2>
            <p className="card-paragraph">High-quality food that is fresh, flavorful, and made to perfection.</p> 
          </div>
          <div className='border-cont'>
            <img src={card_border} alt="bordah" className='card_border'/>
          </div>
        </div>


        <div className="card">
          <div >
            <img src={icon_two} alt="Logo 2" className="card-logo" />
            <h2 className="card-heading">Exciting Drinks</h2>
            <p className="card-paragraph">We offer a range of delicious margaritas, cocktails, and other beverages to complement the delicious Mexican food.</p>
          </div>
          <div className='border-cont'>
            <img src={card_border} alt="bordah" className='card_border'/>
          </div>
        </div>


        <div className="card">
          <div>
            <img src={icon_three} alt="Logo 3" className="card-logo" />
            <h2 className="card-heading" >Events</h2>
            <p className="card-paragraph">Follow us on social media to keep up with events and special offers so we can share more fun-filled meals with you.</p>
          </div>
          <div className='border-cont2'>
            <img src={card_border} alt="bordah" className='card_border'/>
          </div>
      </div>

      </div>
    }
    { isMobile &&
  
    <Swiper
    spaceBetween={6}
    slidesPerView={1}
    loop={true}
      // navigation={true}
      navigation={{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }}
    onSlideChange={() => console.log('slide change')}
    onSwiper={(swiper) => console.log(swiper)}
  >
    <SwiperSlide>
    <div className="card">
          <div>
            <img src={icon_one} alt="Logo 1" className="card-logo" />
             <h2 className="card-heading" >Delicious Food</h2>
             <p className="card-paragraph">High-quality food that is fresh, flavorful, and made to perfection.</p> 
           </div>
           <div className='border-cont'>
            <img src={card_border} alt="bordah" className='card_border'/>
           </div>
         </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="card">
           <div >
            <img src={icon_two} alt="Logo 2" className="card-logo" />
            <h2 className="card-heading">Exciting Drinks</h2>
             <p className="card-paragraph">We offer a range of delicious margaritas, cocktails, and other beverages to complement the delicious Mexican food.</p>
          </div>
           <div className='border-cont'>
             <img src={card_border} alt="bordah" className='card_border'/>
           </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="card">
          <div>
            <img src={icon_three} alt="Logo 3" className="card-logo" />
            <h2 className="card-heading" >Events</h2>
            <p className="card-paragraph">Follow us on social media to keep up with events and special offers so we can share more fun-filled meals with you.</p>
          </div>
          <div className='border-cont2'>
            <img src={card_border} alt="bordah" className='card_border'/>
          </div>
      </div>
    </SwiperSlide>
    {/* <SwiperSlide>Slide 4</SwiperSlide> */}
    <div className="swiper-button-prev" style={prevButtonStyle}></div>
      <div className="swiper-button-next" style={nextButtonStyle}></div>
  </Swiper>
   
  
    }

    </div>
  );
}

export default CardSection;

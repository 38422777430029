import React,{useEffect, useState} from 'react'
import { Helmet } from 'react-helmet';
import Navbar from './Navbar/Navbar'
import HeroD from './TacoHero/HeroD';
import Features from './Features/Features';
import ReservationD from './Reservation/ReservationD';
import Cards from './Cards/Card'
import Socials from './Socials/Social'
import Subscribe from './Subscribe/Subscribe';
import FooterD from './Footer/FooterD';
import Rights from './Rights/Rights';
import FeaturesTwoD from './FeaturesTwo/FeaturesTwoD';
import circular from '../Assets/CircularProgressIndicatorend.gif';
import './LandPage.css'

const App = () => {
  const [isLoading, setLoader] = useState(true)
  useEffect(() => {
    const num = Math.floor(Math.random() * 2000)
    setTimeout(() => {
      setLoader(false)
    },num)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='App'>
      { isLoading ? 
        <div className='background-whito'>
         <img src={circular} className='circular-gif'/>
        </div>
        :
      <>
      <Helmet>
        <title>Lalo's Mexican</title>
      </Helmet>
      <Navbar/>
       <HeroD/>
      {/* <Features/>  */}
      <FeaturesTwoD/>
      <ReservationD/>
      {/* <Cards/> */}
      <Socials/>
      <Subscribe/>
      <FooterD/>
      <Rights/>
      </>
      }
    </div>
  )
}

export default App
import React,{useState} from 'react'
import './Events.css'
import event_1 from '../../../Assets/event_1.png'
import event_2 from '../../../Assets/event_2.png'
import event_3 from '../../../Assets/event_3.png'
import event_4 from '../../../Assets/event_4.png'

const Events = () => {

const images = [{"image":{event_1}}]

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleMouseEnter2 = () => {
        setIsHovered2(true);
      };
    
      const handleMouseLeave2 = () => {
        setIsHovered2(false);
      };
      const handleMouseEnter3 = () => {
        setIsHovered3(true);
      };
    
      const handleMouseLeave3 = () => {
        setIsHovered3(false);
      };
  
      const handleMouseEnter4 = () => {
          setIsHovered4(true);
        };
      
        const handleMouseLeave4 = () => {
          setIsHovered4(false);
        };


  return (
    <div className='event-container'>
        <div className='event-wrap'>
            <div className='event-col-1'>
                <h1 className='event-head'>
                    Sample Events
                </h1>
                <div 
                className='event-images'
                >  

                {/* <div onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ transform: isHovered ? 'scale(1.1)' : 'scale(1)' }}
                     >
                        <img src={event_1} alt='event_1' className='event-photo'/>
                        {isHovered &&
                            <div className='overlay'>
                                <p className='eve-para'>This is the overlay text</p>
                            </div>
                            }
                    </div> */}
                     <div
                        className="image-container"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ transform: isHovered ? 'scale(1.1)' : 'scale(1)' , zIndex: isHovered ? '999' : '0'}}
                    >
                        <img
                        src={event_1}
                        alt="placeholder"
                        className="image"
                        />
                        {isHovered && (
                        <div className="overlay">
                            <h1> Catering </h1>
                            <p className="ovr-text">
                            Breakfast | Lunch | Dinner 
                            </p>
                        </div>
                        )}
                    </div>

                    <div
                        className="image-container"
                        onMouseEnter={handleMouseEnter2}
                        onMouseLeave={handleMouseLeave2}
                        style={{ transform: isHovered2 ? 'scale(1.1)' : 'scale(1)', zIndex: isHovered ? '999' : '0'}}
                    >
                        <img
                        src={event_2}
                        alt="placeholder"
                        className="image"
                        />
                        {isHovered2 && (
                        <div className="overlay">
                            <h1>Assorted Meals</h1>
                            <p className="ovr-text">
                            Corporate Events | At-home parties
                            </p>
                        </div>
                        )}
                    </div>

                    <div
                        className="image-container"
                        onMouseEnter={handleMouseEnter3}
                        onMouseLeave={handleMouseLeave3}
                        style={{ transform: isHovered3 ? 'scale(1.1)' : 'scale(1)' , zIndex: isHovered ? '999' : '0'}}
                    >
                        <img
                        src={event_3}
                        alt="placeholder"
                        className="image"
                        />
                        {isHovered3 && (
                        <div className="overlay">
                            <h1>Food Truck</h1>
                            <p className="ovr-text">
                                Live Food | Festivals
                            </p>
                        </div>
                        )}
                    </div>

                    <div
                        className="image-container"
                        onMouseEnter={handleMouseEnter4}
                        onMouseLeave={handleMouseLeave4}
                        style={{ transform: isHovered4 ? 'scale(1.1)' : 'scale(1)', zIndex: isHovered ? '999' : '0' }}
                    >
                        <img
                        src={event_4}
                        alt="placeholder"
                        className="image"
                        />
                        {isHovered4 && (
                        <div className="overlay">
                            <h1>Delivery Rapido</h1>
                            <p className="ovr-text">
                            Contact us for same-day catering options
                            </p>
                        </div>
                        )}
                    </div>
                        
                    {/* <img src={event_2} alt='event_2' className='event-photo'/>
                    <img src={event_3} alt='event_3' className='event-photo'/>
                    <img src={event_4} alt='event_4' className='event-photo'/> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Events






// import React, { useState } from 'react';
// import './Events.css';

// const App = () => {
//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   return (
//     <div className="container">
//       <div
//         className="image-container"
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         style={{ transform: isHovered ? 'scale(1.1)' : 'scale(1)' }}
//       >
//         <img
//           src="https://via.placeholder.com/400x400"
//           alt="placeholder"
//           className="image"
//         />
//         {isHovered && (
//           <div className="overlay">
//             <p className="text">
//               This is a sample paragraph. Lorem ipsum dolor sit amet,
//               consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
//               labore et dolore magna aliqua.
//             </p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default App;

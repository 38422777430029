import React,{useEffect, useState} from 'react'
import ResHero from '../LandingTwo/ResHero/ResHero'
import NavbarRM from '../Navbar/NavbarRM'
import FeaturesM from './Features/FeaturesM'
import Events from './Events/Events'
import Testimonials from './Testimonials/Testimonials'
import Subscribe from './Subscribe/Subscribe'
import FooterM from '../Footer/FooterM'
import Rights from '../Rights/Rights';
import ResData from './ResData/ResData'
import './LandPageCat.css' 
import circular from '../../Assets/CircularProgressIndicatorend.gif'

const LandingTwo = () => {
  const [isLoading, setLoader] = useState(true)
  useEffect(() => {
    const num = Math.floor(Math.random() * 2000)
    setTimeout(() => {
      setLoader(false)
    },num)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
       { isLoading ? 
        <div className='background-whito'>
         <img src={circular} className='circular-gif'/>
        </div>
        :
          <>
            <NavbarRM/>
            <ResHero/>
            <FeaturesM/>
            <Events/>
            <ResData/>
            <Testimonials/>
            <Subscribe/>
            <FooterM/>
            <Rights/>
          </>
        }
    </div>
  )
}

export default LandingTwo;
import React, { useState, useEffect } from 'react';
import './Hero.css';
import { useScroll, motion, useTransform, useMotionValueEvent } from "framer-motion";
import backimg from '../../Assets/ImageSlider.png'
import { Link } from 'react-router-dom';
import img_5 from '../../Assets/5.jpg'
import img_6 from '../../Assets/6.jpg'
import img_7 from '../../Assets/7.jpg'

const Hero = () => {
  // // Create an array of image URLs to use for the slideshow
  // const [images, setImages] = useState([
  //   // 'https://lh3.googleusercontent.com/p/AF1QipOPgDs0PvqkvifhnIfVMSvVCuwJf6iNMI5PlR83=w1080-h608-p-no-v0',
  //   'https://images.unsplash.com/photo-1577017040065-650ee4d43339?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
  //   'https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
  // ]);
  
  // // Create a state variable to keep track of the current image index
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // // Function to advance to the next image in the slideshow
  // const goToNextImage = () => {
  //   const newIndex = (currentImageIndex + 1) % images.length;
  //   setCurrentImageIndex(newIndex);
  // };

    
     

  // Start the slideshow automatically
  // useEffect(() => {
  //   const intervalId = setInterval(goToNextImage, 5000);
  //   return () => clearInterval(intervalId);
  // }, [currentImageIndex]);


  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    { image: img_5, caption: "Slide 1", description: "This is slide 1" },
    { image: img_6, caption: "Slide 2", description: "This is slide 2" },
    { image: "https://lh3.googleusercontent.com/p/AF1QipOPgDs0PvqkvifhnIfVMSvVCuwJf6iNMI5PlR83=w1080-h608-p-no-v0", caption: "Slide 3", description: "This is slide 3" },
    { image: img_7, caption: "Slide 3", description: "This is slide 3" },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
    }, 3000);
    return () => {
      clearInterval(slideInterval);
    };
  }, [currentSlide, slides.length]);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };
  

  return (
    <div className="hero-section">
      {/* <div className="slideshow-container">
        {images.map((imageUrl, index) => (
          <div
            key={index}
            className={index === currentImageIndex ? 'slide active' : 'slide'}
            style={{ backgroundImage: `url(${imageUrl})` }}
          ></div>
        ))}
      </div> */}


            <div className="slideshow-container">
                {slides.map((slide, index) => (
                <div
                    className={`slide-image ${index === currentSlide ? "active" : ""}`}
                    key={index}
                    style={{ backgroundImage: `url(${slide.image})` }}
                >
                </div>
                ))}
            </div>




      <div className="hero-content">
          <motion.h1 
              className='heading_main'
              
              initial={{ y: -500, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1.1, type:"spring",delay:0.5 }}
          >
            Hudson
          
          </motion.h1>


          <div className='reverse-div'> 
           <div>
              <div className='buttons_one'>
                <Link to="https://www.toasttab.com/lalos-mexican-restaurant-158-main-st" target='_blank'><motion.button 
                className='button_one noHover'
                // onClick={() => }
                initial={{ x: -700, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.1, type:"spring",delay:0.5 }}
                >
                  Order Now
                </motion.button></Link>

                <Link to={"/hudson/menu"}><motion.button
                initial={{ x: 700, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.1, type:"spring",delay:0.5 }} 
                className='button_two noHover'
                
                >
                  Explore Menu
                </motion.button></Link>
              </div>
            </div>

            <div>
          <Link to='https://www.google.com/maps/place/Lalos+Mexican+restaurant/@42.3914668,-71.5677837,17z/data=!3m1!4b1!4m6!3m5!1s0x89e38d63464c04dd:0x1ce236cfccb26376!8m2!3d42.3914629!4d-71.5652088!16s%2Fg%2F11rwvg3fbl' style={{ color:"white" }}>
            <motion.p className='para_main'
            
            initial={{ y: 500, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1.1, type:"spring",delay:0.5 }}
            style={{ textDecorationLine:"underline",fontWeight:"600" }}
          >
            158 Main St, Hudson, MA 01749, USA
          </motion.p></Link>
          <motion.p className='para_main'
            
            initial={{ y: 500, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1.1, type:"spring",delay:0.5 }}
            style={{ textDecorationLine:"underline",fontWeight:"600" }}
          >
            <a href='tel:9783107246' style={{ color:'white' }}>978.310.7246</a>
          </motion.p>
          </div>
        </div>
        </div>

        <div className="indicators">
                {slides.map((slide, index) => (
                <div
                    className={`indicator ${index === currentSlide ? "active" : ""}`}
                    key={index}
                    onClick={() => handleIndicatorClick(index)}
                />
                ))}
            </div>          

    </div>
  );
};

export default Hero;

import React,{ useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import './ContactUs.css';
import NavbarM from '../Navbar/NavbarM';
import FooterM from '../Footer/FooterM';

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [verified, setVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [done, setDone] = useState(false);
  const [formData, setFormData] = useState({
    firstname:'',
    phone:'',
    email: '',
    reason:'',
    message: '',
    contactType:'Contact',
    lastname:''
  });
  const [clientIp, setClientIp] = useState('');

  useEffect(() => {
    axios({
      method:'GET',
      url:'https://geolocation-db.com/json/67273a00-5c4b-11ed-9204-d161c2da74ce',
      headers:{"Content-Type":"application/json"},
    })
      .then(response => {
         setClientIp(response.data.IPv4);
        // console.log(response.data.IPv4)
      })
      .catch(error => {
        // console.error('Error:', error);
      });
  }, []);
 


  const handleNumberChange = (event) => {
    const inputValue = event.target.value;
    setPhoneNumber(inputValue);
    setIsValidPhoneNumber(validatePhoneNumber(inputValue));
  };
  
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setIsValidEmail(validateEmail(inputValue));
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  function onChange(value) {
    setVerified(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(formData)
    const name = `${formData.firstname} ${formData.lastname}`;
    const data = { ...formData, name, userIP:clientIp};
    
    axios({
      method: 'POST',
      url: 'https://web1.altapotentia.com/api/lalos-users',
      headers:{"Content-Type":"application/json"},
      data: {data:data}
    })
      .then(response => {
        // console.log('Data sent successfully!', response);
        setDone(true)
      })
      .catch(error => {
        // console.error('Failed to send data!', error);
      });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(formData.email);
  }

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{10}$/;
    return regex.test(formData.phonenumber);
  };

  // console.log('Done??? : ',done)
  return (
    <>
    <div className="container">
        <NavbarM/>
      <div className="roww">
        <div className="col-left">
          <h2 className='col-left-head'>Contact Us</h2>
          <p className='col-left-para'>
          Send us a message and we will get back to you as soon as possible. Looking forward to hear from you!
          </p>
        </div>
        <div className="col-right">
          {/* <form  className='form-one' action="mailto:edgarceja90@gmail.com" method="post" enctype="text/plain"> */}
          <form  className='form-one' action="mailto:edgarceja90@gmail.com" method="post"  onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input type="text" className="form-control" name="firstname" placeholder='Your First Name' id="firstName" value={formData.firstname} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input type="text" className="form-control" name="lastname" placeholder='Your Last Name' id="lastName" value={formData.lastname} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" placeholder='Your Email' id="email" name="email" value={formData.email} onChange={handleChange} required />
              {!isValidEmail && <p style={{ color:"red", fontSize:"1.4vh" }} >*Please enter a valid email address</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input className="form-control"  placeholder='Your Phone Number' id="phone" type='tel' name="phone" value={formData.phone} onChange={handleChange} required />
              {!isValidPhoneNumber && (<p style={{ color:"red", fontSize:"1.4vh" }}>Please enter a valid 10-digit phone number</p>)}
            </div>
            <div className="form-group">
              <label htmlFor="selectOption">Select Option</label>
              <select className="form-control" id="reason" required name='reason' value={formData.reason} onChange={handleChange}>
                <option className ="first-option" value="Option 1">-Select option-</option>
                <option value="General">General</option>
                <option value="Food Truck">Food Truck</option>
                <option value="Catering">Catering</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" id="message" rows="10" name='message' value={formData.message} onChange={handleChange}></textarea>
            </div>
            <div className='re-cap' style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
              <ReCAPTCHA
                sitekey="6Lf2l6glAAAAAGebEU69M6Y7q6L5PfTkHX_nEcC1"
                onChange={onChange}
              />
              </div>
            {!done ? 
              <button type="submit" value="Send" className={verified ? 'btn-primary' : 'btn-blank'} disabled={!verified}>Submit</button>
              :
              <button className='done-btn' disabled={true}>Request Sent !!</button>
            }
            
          </form>
        </div>
      </div>
    </div>
    <div className='footru-up'>
    <FooterM/>
    </div>
    </>
  );
}

export default ContactUs;

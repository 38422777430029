import React,{ useState, useEffect } from 'react'
import NavbarH from '../Navbar/NavbarH'
import './Menu.css'
import MenuCard from '../../Components/MenuCard/MenuCard'
import menuJson from '../JSON/menu.json'
import Footer from '../Footer/Footer'
import imageee from '../../Assets/menu_back.png'
import { json } from 'react-router-dom'


const Menu = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    const [selectedButton, setSelectedButton] = useState(1);
    const [backImf, setBackImf] = useState('')
    const [menuData, setMenuData] = useState(menuJson)
    // const [menuArray, setMenuArray] = useState([])
    const [spcData, setSpcData] = useState([])
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleButtonClick = (id) => {
        setSelectedButton(id);
      };

        useEffect(() => {
            const specificData = menuData.filter((item) => item.id === selectedButton);
            setSpcData(specificData[0])
            // const backk = JSON.stringify(spcData.backImg)
            // setBackImf(backk)
            // console.log(backk)
        },[selectedButton])
      
    //   console.log(spcData)
      const backgroundImage = imageee

      var divStyle = {
        backgroundImage: `url(${spcData.backImg})`,
        backgroundAttachment:"fixed" ,
        backgroundSize: "cover",
        backgroundRepeat:"no-repeat",
        height: "100vh",
        maxWidth:"100%",
        width:"100vw",
        height:"100%",
        marginTop:"0"
        
      }
   
  return (
    <div className = 'menu-container' 
    style={divStyle}
    
    >
        <NavbarH/>
        <img
        />
        <div className='menu_items'>
            <div className='menu-back'>
            <div className='menu_1'>
                <h1 className={selectedButton === 1 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(1)}>STEAK</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 2 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(2)}> APPETIZERS</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 3 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(3)}>FAJITAS</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 4 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(4)}>TACOS</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 5 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(5)}>BURGERS</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 6 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(6)}>PORK</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 7 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(7)}>SALADS</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 8 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(8)}>FAVORITES</h1><h1 className='menu_button_2'>|</h1>

            </div>
            <div className='menu_2'>
                <h1 className={selectedButton === 9 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(9)}>A LA CARTE</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 10 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(10)}> CHICKEN</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 11 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(11)}>SEAFOOD</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 12 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(12)}>COMBOS</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 13 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(13)}>KIDS MENU</h1><h1 className='menu_button_2'>|</h1>

                <h1 className={selectedButton === 14 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(14)}>BREAKFAST</h1><h1 className='menu_button_2'> |</h1>

                <h1 className={selectedButton === 15 ? 'menu_button' : 'menu_button_2'}
                    onClick={() => handleButtonClick(15)}>BEVERAGES</h1>

            </div>
            </div>
        
            
                <div>
                    <h1 className='card-head'>{spcData.name}</h1>
                </div>
                <div>
                    <h1 className='card-info'> {spcData.info} </h1>
                </div>



                <div className='menu_cards_container'>
                <div className='menu_cards'> 
            { spcData?.menu?  
                spcData.menu.map((item) => {
                    return(     
                        <div> 
                            <MenuCard 
                            name={item.name}
                            price={item.price}
                            parah={item.description}
                            />   
                        </div>   
                    )     
                })
            : null
            }
          </div>
          </div>



          {spcData.name_two? 
          <>
            <div>
                <h1 className='card-head'>{spcData.name_two}</h1>
                <h1 className='card-info'>{spcData.info_two}</h1>
            </div>
             <div className='menu_cards_container'>
             <div className='menu_cards'> 
            { spcData?.menu_two?  
             spcData.menu_two.map((item) => {
                 return(     
                     <div> 
                         <MenuCard 
                         name={item.name}
                         price={item.price}
                         parah={item.description}
                         />   
                     </div>   
                 )     
             })
         : null
         }
       </div>
       </div>
       </>
            :
            ""}


        {   spcData.name_three? 
            <>
            <div>
                <h1 className='card-head'>{spcData.name_three}</h1>
                <h1 className='card-info'>{spcData.info_three}</h1>
            </div>
            <div className='menu_cards_container'>
             <div className='menu_cards'> 
            { spcData?.menu_three?  
             spcData.menu_three.map((item) => {
                 return(     
                     <div> 
                         <MenuCard 
                         name={item.name}
                         price={item.price}
                         parah={item.description}
                         />   
                     </div>   
                 )     
             })
         : null
         }
       </div>
       </div>

            </>
            :
            ""}

          






          <p className='card-note'> {spcData.note} </p>
        </div>
        <Footer/>
    </div>
  )
}

export default Menu
// import React,{ useState, useEffect } from 'react'
// import NavbarM from '../Navbar/NavbarM'
// import './Menu.css'
// import MenuCard from '../../Components/MenuCard/MenuCard'
// import menuJson from '../JSON/menu.json'
// import imageee from '../../Assets/menu_back.png'
// import FooterM from '../Footer/FooterM'
// import { json } from 'react-router-dom'
// import axios from 'axios';

// const Menu = () => {

//     const [selectedButtonTwo, setSelectedButtonTwo] = useState(26);
//     const [apiData, setApiData] = useState([]);
//     // const [menuArray, setMenuArray] = useState([])
//     const [spcDataTwo, setSpcDataTwo] = useState(apiData[0]);
//     const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//     useEffect(() => {
//         window.scrollTo(0, 0);
        
//       }, []);

//       useEffect(() => {
//         const fetchData = async () => {
//             try {
//               const response = await axios.get('https://web1.altapotentia.com/api/lalos?populate=menus');
//               const data = response.data;
//             //   setApiData(data.data[0].attributes.menus.data)
//             setApiData(data.data)
//             setSpcDataTwo(data.data[0])
//             // console.log(data.data)
//             } catch (error) {
//               console.error(error);
//             }
            
//           };
//           fetchData() 

//       },[])

//     useEffect(() => {
//         const handleResize = () => {
//         if (window.innerWidth <= 768) {
//             setIsMobile(true);
//         } else {
//             setIsMobile(false);
//         }
//         };
//         window.addEventListener('resize', handleResize);

//         return () => window.removeEventListener('resize', handleResize);    
//     }, []);

// console.log(apiData)
    
//       useEffect(() => {
//         const specificDataTwo = apiData.filter((item) => item.id === selectedButtonTwo);
//         setSpcDataTwo(specificDataTwo[0])        
//         // const backk = JSON.stringify(spcData.backImg)
//         // setBackImf(backk)
//         // console.log(backk)
//     },[selectedButtonTwo ? selectedButtonTwo : apiData])


//     console.log(spcDataTwo)

//       const handleButtonClickTwo = (ids) => {
//         setSelectedButtonTwo(ids);
//       };
//       console.log(selectedButtonTwo)
//     //   const selData = apiData.filter((item) => item)
//     //   console.log(selData)
//     //   console.log(spcData)    
//       var divStyle = {
//         backgroundImage: `url(${spcDataTwo ? spcDataTwo.attributes.backImg : ''})`,
//         backgroundAttachment:"fixed" ,
//         backgroundSize: "cover",
//         backgroundRepeat:"no-repeat",
//         height: "100vh",
//         maxWidth:"100%",
//         width:"100vw",
//         height:"100%",
//         marginTop:"0"
//       }
    
   
//     //   console.log(spcDataTwo?.attributes?.menus?.data); 
//     //   console.log(spcDataTwo); 
      
//   return (
//     <div className = 'menu-container' 
//     style={divStyle}
//     >
//         <NavbarM/>
//         <img
//         />
//         <div className='menu_items'>
//             <div className='menu-back'>
//             <div className='menu_1'>
//                 <h1 className={selectedButtonTwo === 26 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(26)}>STEAK</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 27 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(27)}> APPETIZERS</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 28 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(28)}>FAJITAS</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 29 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(29)}>TACOS</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 30 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(30)}>BURGERS</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 31 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(31)}>PORK</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 32 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(32)}>SALADS</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 33 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(33)}>FAVORITES</h1><h1 className='menu_button_2'>|</h1>

//             </div>
//             <div className='menu_2'>
//                 <h1 className={selectedButtonTwo === 34 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(34)}>A LA CARTE</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 35 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(35)}> CHICKEN</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 36 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(36)}>SEAFOOD</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 37 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(37)}>COMBOS</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 38 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(38)}>KIDS MENU</h1><h1 className='menu_button_2'>|</h1>

//                 <h1 className={selectedButtonTwo === 39 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(39)}>BREAKFAST</h1><h1 className='menu_button_2'> |</h1>

//                 <h1 className={selectedButtonTwo === 40 ? 'menu_button' : 'menu_button_2'}
//                     onClick={() => handleButtonClickTwo(40)}>BEVERAGES</h1>

//             </div>
//             </div>
        
//                 {spcDataTwo ?
//                 <div>
//                     <h1 className='card-head'>{spcDataTwo.attributes.name}</h1>
//                 </div>
//                 :
//                 <div>
//                     <h2 className='card-head'>Loading..</h2>
//                 </div>
//                  }

//                 {spcDataTwo ? 
//                      <>
//                      <div>
//                          <h1 className='card-info'> {spcDataTwo.attributes.info} </h1>
//                      </div>
             
             
 
//                      <div className='menu_cards_container'>
//                      <div className='menu_cards'> 
//                      { spcDataTwo?.attributes?.menus?.data?  
//                          spcDataTwo?.attributes?.menus?.data.map((item) => {
//                              return(     
//                                  <div> 
//                                      <MenuCard 
//                                      name={item.attributes.name}
//                                      price={item.attributes.price}
//                                      parah={item.attributes.description !== 'undefined' ? item.attributes.description : ''}
//                                      />   
//                                  </div>   
//                              )     
//                          })
//                      : null
//                      }
//                      </div>
//                      </div> 
//                  </>
//                 :
//                 <div>
//                     <h2>Loading..</h2>
//                 </div>
//                 }
                
//             {spcDataTwo ?
//                     <>
//                         <div>
//                         { spcDataTwo.id == 40?  <h1 className='card-head'>{apiData[15].attributes.name}</h1> : ''}
//                         { spcDataTwo.id == 40?  <h1 className='card-info'>{apiData[15].attributes.info}</h1> : ''}
//                         </div>
//                         <div className='menu_cards_container'>
//                         <div className='menu_cards'> 
//                         { spcDataTwo.id == 40?  
//                         apiData[15].attributes.menus.data.map((item) => {
//                             return(     
//                                 <div> 
//                                     <MenuCard 
//                                     name={item.attributes.name}
//                                     price={item.attributes.price}
//                                     parah={item.attributes.description !== 'undefined' ? item.attributes.description : ''}
//                                     />   
//                                 </div>   
//                             )     
//                         })
//                     : null
//                     }
//                 </div>
//                 </div>
//                 </>
                        
//                     :
//                     <div>
//                         <h2>Loading..</h2>
//                     </div>
//             }

//           {spcDataTwo ?
//                     <>
//                         <div>
//                         { spcDataTwo.id == 40?  <h1 className='card-head'>{apiData[16].attributes.name}</h1> : ''}
//                         { spcDataTwo.id == 40?  <h1 className='card-info'>{apiData[16].attributes.info}</h1> : ''}
//                         </div>
//                         <div className='menu_cards_container'>
//                         <div className='menu_cards'> 
//                         { spcDataTwo.id == 40?  
//                         apiData[16].attributes.menus.data.map((item) => {
//                             return(     
//                                 <div> 
//                                     <MenuCard 
//                                     name={item.attributes.name}
//                                     price={item.attributes.price}
//                                     parah={item.attributes.description !== 'undefined' ? item.attributes.description : ''}
//                                     />   
//                                 </div>   
//                             )     
//                         })
//                     : null
//                     }
//                 </div>
//                 </div>
//                 </>
                        
//                     :
//                     <div>
//                         <h2>Loading..</h2>
//                     </div>
//             }
//            { spcDataTwo ? <p className='card-note'> {spcDataTwo.attributes.note} </p> : ''} 
//         </div>
//         <FooterM/>
//     </div>
//   )
// }

// export default Menu
// import React from 'react'
// import Navbar from './Landing/Navbar/Navbar';
// import Hero from './Landing/TacoHero/Hero';
// import Features from './Landing/Features/Features';
// import Reservation from './Landing/Reservation/Reservation';
// import Cards from './Landing/Cards/Card'
// import Socials from './Landing/Socials/Social'
// import Subscribe from './Landing/Subscribe/Subscribe';
// import Footer from './Landing/Footer/Footer';
// import Rights from './Landing/Rights/Rights';
// import FeaturesTwo from './Landing/FeaturesTwo/FeaturesTwo';

// const App = () => {
//   return (
//     <div className='App'>
//       <Navbar/>
//       <Hero/>
//       <Features/>
//       <FeaturesTwo/>
//       <Reservation/>
//       <Cards/>
//       <Socials/>
//       <Subscribe/>
//       <Footer/>
//       <Rights/>
//     </div>
//   )
// }

// export default App

import React from 'react'
import { Route, Routes,Router } from "react-router-dom"
import  Aboutus from './Landing/About/Aboutus'
import AboutusM from './Landing/About/AboutusM' 
import AboutusD from './Landing/About/AboutusD'
import LandPage from './Landing/LandPageD'
import Marl from './Landing/LandPageM'
import Hud from './Landing/LandPageH'
import Menu from './Landing/Menu/Menu'
import MenuM from './Landing/Menu/MenuM'
import ContactUs from './Landing/ContactUs/ContactUs'
import ContactUsM from './Landing/ContactUs/contactUsM'
import LandingTwo from './Landing/LandingTwo/LandingTwo'
import LandingTwoM from './Landing/LandingTwo/LandingTwoM'
import PrivacyPolicy from './Landing/Privacy/Privacy'
import PrivacyPolicyH from './Landing/Privacy/PrivacyH'
import PrivacyPolicyM from './Landing/Privacy/PrivacyM'
import ContactUsD from './Landing/ContactUs/ContactUsD'
import LandingTwoD from './Landing/LandingTwo/LandingTwoD'


const App = () => {
  return ( 
    <Routes>
        <Route path="/" element={<LandPage/>}/>
        <Route path="/hudson/about-us" element={<Aboutus/>}/>
        <Route path="/marlborough/about-us" element={<AboutusM/>}/>
        <Route path="/about-us" element={<AboutusD/>}/>
        <Route path="/marlborough" element={<Marl/>}/>
        <Route path="/hudson" element={<Hud/>}/>
        <Route path="/hudson/menu" element={<Menu/>}/>
        <Route path="/marlborough/menu" element={<MenuM/>}/>
        <Route path="/hudson/contact-us" element={<ContactUs/>}/>
        <Route path="/marlborough/Contact-us" element={<ContactUsM/>}/>
        <Route path="/contact-us" element={<ContactUsD/>}/>
        <Route path="/hudson/catering" element={<LandingTwo/>}/>
        <Route path="/marlborough/catering" element={<LandingTwoM/>}/>
        <Route path="/catering" element={<LandingTwoD/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/hudson/privacy-policy" element={<PrivacyPolicyH/>}/>
        <Route path="/marlborough/privacy-policy" element={<PrivacyPolicyM/>}/>
    </Routes>
  )
}

export default App
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import './Subscribe.css';

const Subscribe = () => {
  const [subscribed, setSubscribed] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    phone:'0000000000',
    email: '',
    contactType:'Subscriber',
  });
  const [clientIp, setClientIp] = useState('');

  useEffect(() => {
    axios({
      method:'GET',
      url:'https://geolocation-db.com/json/67273a00-5c4b-11ed-9204-d161c2da74ce',
      headers:{"Content-Type":"application/json"},
    })
      .then(response => {
         setClientIp(response.data.IPv4);
        // console.log(response.data.IPv4)
      })
      .catch(error => {
        // console.error('Error:', error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(formData)
    const data = { ...formData, userIP:clientIp};

    axios({
      method: 'POST',
      url: 'https://web1.altapotentia.com/api/lalos-users',
      headers:{"Content-Type":"application/json"},
      data: {data:data}
    })
      .then(response => {
        console.log('Data sent successfully!', response);
        setSubscribed(true)
      })
      .catch(error => {
        console.error('Failed to send data!', error);
      });
  };

  const handleChange = (event) => {
    // const inputValue = event.target.value;
    // setEmail(inputValue);
    // setIsValidEmail(validateEmail(inputValue));
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };




  return (
    <div className="subscribe-container-ts">
      <div className="subscribe-wrap-ts">
        <div className="subscribe-para-ts">
          <p className='sub-para-ts'>Don't miss out on upcoming events and exclusive discounts - sign up for our email list today!</p>
        </div>
        <div>
        <form onSubmit={handleSubmit} className="subscribe-content-ts">
                <input type="text" name="name" placeholder="Your Name" className="subscribe-input" value={formData.name} onChange={handleChange} />
                <input type="email" name="email"placeholder="Your email address" className="subscribe-input" value={formData.email} onChange={handleChange}  />
                {/* <button type="submit" value="Send" className="subscribe-button">Subscribe</button> */}
                {
                  subscribed ? 
                  <button className="subscribed-button-ts" disabled={true}>Subscribed</button>
                  :
                  <button type="submit" value="Send" className="subscribe-button-ts">Subscribe</button>
                }
              </form>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;

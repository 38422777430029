import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../../Assets/lalologo.svg';
import {RiArrowDropDownLine } from "react-icons/ri"
import { GrLocation } from "react-icons/gr"
import { TfiLocationPin } from "react-icons/tfi"
import { IoIosArrowDown } from 'react-icons/io'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { Link } from 'react-router-dom';

function Dropdown (props) {
  return(
    <ul>
      <li>{props.name}</li>
    </ul>
  );
}

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdown, setDropdown] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function handleClick() {
    window.location.reload();
  }

  return (
    
       <nav className={`navbar-pp ${isScrolled ? 'navbar-white' : ''}`}> 
      <div className='navbar-container'>
        <div className='navbar-left'>
          <div>
          <Link to={"/"}><img src={logo} alt="Logo" className={`icon-lft-main ${isScrolled ? 'icon-lft-main-white' : ''}`}/></Link>
          </div>
          <ul className='navbar-list-left'>
            {/* <Link style={{ textDecoration:"none"}}><li className='listoo'><a>Menu</a></li></Link> */}
            {/* <Link to={"/menu"} style={{ textDecoration:"none"}}><li className={isScrolled ? 'listoo-sc' : 'listoo'}><a>Menu</a></li></Link>
            <Link to="https://www.toasttab.com/lalos-mexican-restaurant-158-main-st" style={{ textDecoration:"none"}}><li className={isScrolled ? 'listoo-sc' : 'listoo'}>Order Online</li></Link>
           <Link style={{ textDecoration:"none"}}><li className={isScrolled ? 'listoo-sc' : 'listoo'}>Catering</li></Link> */}
          </ul>
        </div>

        <div className='navbar-right'>
          <div>
        <p className='loce'><HiOutlineLocationMarker className='loce-icon'/><a onClick={() => setDropdown(!dropdown)}>Select Location</a><IoIosArrowDown className='loce-arrow' onClick={() => setDropdown(!dropdown)}/></p>
        {/* style={{color: 'orange', fontSize: '50px',}} */}
          </div>
          <div className='dropdown-container'>
            {
              dropdown && 
              <div className='dropdown'>
                <Link to ="/hudson" style={{ textDecoration:"none",marginLeft:"-20px",marginRight:"20px",color:"black"  }} > <Dropdown name={"Hudson, MA"}/> </Link>
                <Link to ="/marlborough" style={{ textDecoration:"none",marginLeft:"-20px",marginRight:"20px",color:"black"  }}><Dropdown name={"Marlborough, MA"} /> </Link>
              </div>
              
            }
          </div>
        </div>
      
      </div>
     
    </nav>
  );
};

export default Navbar;




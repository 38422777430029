import React,{useEffect,useState} from 'react';
import './Features.css';
import { useInView } from 'react-intersection-observer';
import fea_back from '../../Assets/orange_back.svg'
import fea_one from '../../Assets/fea1.png'
import fea_onee from '../../Assets/fea-img.png'
import fea_two from '../../Assets/fea_two.svg'
import fea_img_m from '../../Assets/fea_img_m.png'
import fea_nn_one from '../../Assets/fea-nn-one.png'
import { useScroll, motion, useTransform, useMotionValueEvent, useAnimation  } from "framer-motion";
import { Link } from 'react-router-dom';

const image1 = "https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
const image2 = "https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"



const Features = () => {

  const [ref, inView] = useInView({
    threshold:0.3
  });
  const animation = useAnimation();
  const [isInView, setIsInView] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
   
    

  // console.log(inView)

  // useEffect(() => {
  //   if(inView){
  //     animation.start({
  //       x:0,
  //       scale:0.8,
  //       transition:{
  //         type:'spring',
  //         duration:1.1
  //       }
  //     })
  //   }
  //   if(!inView){
     
  //     animation.start({
  //       x:400,
  //       scale:5,
  //       transition:{
  //         duration:1.1,
  //         type:'spring'
  //       }
  //     });
  //   }
  // },[hasAnimated])

  // const shouldAnimate = inView;

  // if (shouldAnimate) {
  //   setHasAnimated(true);
  // }
  
  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);
  
  return (
    <div className="features-section" ref={ref}>

{ !isMobile &&
      <div className="feature-row" >
        <div>
        
          
          <motion.img 
          style={{borderRadius:"20px"}}
          src={fea_nn_one} alt="Feature 1" 
          className='feature-image'
          initial={{ x:400, scale:5, }}
          animate={{ x: isInView ? 0 : 400 , scale: isInView ? 0.65 : 5 }}
          transition={{ duration:1.1, type:'spring' }}
          />
          
         
          {/* <img
          style={{borderRadius:"20px"}}
          src={fea_onee} alt="Feature 1" 
          className='feature-image2'
          // animate={{ x: -100 , scale: 1 }}
          // transition={{ duration:1.1, type:'spring' }}
          /> */}
        </div>
        
        <div className="feature-content">
          <h2 className='feahead'>Fresh Mexican Cuisine - Deliciously Authentic!</h2>
          <p className='feapara'>A tantalizing mix of Mexican flavors and traditional dishes, with something for everyone.</p>
          <Link to="/hudson/menu" className='button_feaa'><button className='button_fea'>Explore Menu</button></Link>
        </div>
      </div>
    }
    {isMobile && 
    <div className='fea-m'>

    <div className='fea-abv-m'>
     <img src={fea_img_m} className='fea-img-1'></img>
    </div>

    <div className='fea-bel-m'>
      <h2 className='feahead'>Fresh Mexican Cuisine - Deliciously Authentic!</h2>
      <p className='feapara'>A tantalizing mix of Mexican flavors and traditional dishes, with something for everyone.</p>
      <Link to="/hudson/menu" className='button_feaa'><button className='button_fea'>Explore Menu</button></Link>
    </div>

    </div>
    }
    {console.log(isMobile)}

    </div>
  );
};

export default Features;

import React,{ useEffect } from 'react';
import './Aboutus.css';
import photoabt from '../../Assets/Aboutusimg.png';
import NavbarM from '../Navbar/NavbarM';
import FooterM from '../Footer/FooterM';

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='aboutus-wrap'>
      <NavbarM/>
    <div className="about-us-container">
      <div className="left-column">
        <h2 className='abthead'>About Us</h2>
        <p className='abtp'>Delightful food and experience! At Lalo's we are devoted to you enjoying unmatched Mexican food and craft cocktails with your friends and family. Lalo's is owned by the enthusiastic and warm, brother & sister duo Edgar and Vanessa.</p>
        <p className='abtp'>Currently located in Hudson and Marlborough, our cozy atmosphere and friendly staff make us the perfect place to grab a bite or settle in for a relaxing, hearty meal.</p>
        </div>
      <div className="right-column">
        <img src={photoabt} alt="Company Team" className='imgabt' />
      </div>
      
    </div>
    <FooterM/>
    </div>
  );
};

export default Aboutus;

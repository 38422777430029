import React from 'react';
import './Reservation.css'; // import your styles file
import { Link } from 'react-router-dom';

function Reservation() {
  return (
    <div className="reser-section">
      <div className="reser-image"></div>
      <div className="reser-content">
        <h1 className="reser-heading">Indulge in the Lalo's Catering Experience</h1>
        <p className="reser-paragraph">We offer a variety of menu options and packages that can fit any budget or party size. We will help you create the perfect menu for your event, contact us today to learn more.</p>
        <Link to="/marlborough/catering" className='button_feaa_catt'><button className='button_fea_catt'>Learn More</button></Link>
      </div>
    </div>
  );
}

export default Reservation;

import React,{ useEffect, useState } from 'react'
import './Testimonials.css'

import SwiperCore, { Navigation,Pagination,Autoplay } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/swiper-bundle.css'
// import 'swiper/components/navigation/navigation.min.css';
// import 'swiper/components/pagination/pagination.min.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);


const Testimonials = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const prevButtonStyle = {
    color: 'white',
    fontSize: '2vw',
    fontWeight: 'bold',
  };
  const nextButtonStyle = {
    color: 'white',
    fontSize: '2vw',
    fontWeight: 'bold',
  };
  const prevButtonStyleM = {
    color: 'rgb(260,260,260,0)',
    fontSize: '1px',
    fontWeight: 'bold',
  };
  const nextButtonStyleM = {
    color: 'rgb(260,260,260,0)',
    fontSize: '1px',
    fontWeight: 'bold',
  };
  


  return (
    <div className='test-container'>
        <div className='test-wrap'>
            <h1 className='test-head'>Testimonials</h1>
            <div className='slide-wrap'>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                navigation={{
                  prevEl: '.swiper-button-prev',
                  nextEl: '.swiper-button-next',
                }}
                 
                pagination={{ el: '.swiper-pagination' }}
                loop={true}
                autoplay={{
                  delay: 7000,
                  disableOnInteraction: true,
                }}
              >
                <SwiperSlide>
                  <div className='testimonial-card'>
                    <h1 className='slide-head'>Wedding Catering</h1>
                    <p className='slide-para'>“The food was absolutely delicious and the presentation was beautiful. We got plenty food for everyone, and a lot of compliments for the quality of food. We had smooth experience planning our wedding catering with Lalo’s, would definitely recommend your services and use them again in the future!"</p>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                <div className='testimonial-card'>
                    <h1 className='slide-head'>Corporate Party</h1>
                    <p className='slide-para'>“We had their catering service for our company's annual holiday party and it was amazing. The food was delicious, the delivery was well in time, everyone was so impressed. The staff was also very friendly and accommodating.”</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className='testimonial-card'>
                    <h1 className='slide-head'>At-home Party </h1>
                    <p className='slide-para'>“I ordered same-day catering to celebrate my daughter and her friends' graduation. I was happy with the variety presented to me given the short time. We had a lovely time with fresh, tasty food. The service was exceptionally prompt."</p>
                  </div>
                </SwiperSlide>
                
                <div className="swiper-button-prev" style={isMobile ? prevButtonStyleM : prevButtonStyle}></div>
                <div className="swiper-button-next" style={isMobile ? nextButtonStyleM : nextButtonStyle}></div>
                <div className="swiper-pagination" ></div>
              </Swiper>
            </div>
        </div>
    </div>
  )
}

export default Testimonials
import React,{useEffect, useState} from 'react'
import ResHero from '../LandingTwo/ResHero/ResHero'
import NavbarR from '../Navbar/NavbarR'
// import Features from './Features/Features'
import Events from './Events/Events'
import Testimonials from './Testimonials/Testimonials'
import Subscribe from './Subscribe/Subscribe'
import Footer from '../Footer/Footer'
import Rights from '../Rights/Rights';
import FeaturesH from '../LandingTwo/Features/FeaturesH'
import ResData from './ResData/ResData'
import './LandPageCat.css' 
import circular from '../../Assets/CircularProgressIndicatorend.gif'
 
const LandingTwo = () => {
  const [isLoading, setLoader] = useState(true)
  useEffect(() => {
    const num = Math.floor(Math.random() * 2000)
    setTimeout(() => {
      setLoader(false)
    },num)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      { isLoading ? 
        <div className='background-whito'>
         <img src={circular} className='circular-gif'/>
        </div>
        :
        <>
        <NavbarR/>
        <ResHero/>
        <FeaturesH/>
        <Events/>
        <ResData/>
        <Testimonials/>
        <Subscribe/>
        <Footer/>
        <Rights/>
        </>
      }
    </div>
  )
}

export default LandingTwo;
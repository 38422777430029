import React from 'react'
import './MenuCard.css'

const MenuCard = props => {
  return (
    <div className='menu-card-container'>
        <div className='menu-head'>
        <h1 className='menu-name'>{props.name}</h1>
        <h1 className='menu-price'>${props.price}</h1>
        </div>
        <p className='menu-parah'>{props.parah}</p>
    </div>
  )
}

export default MenuCard
import React, { useState, useEffect } from 'react';
import './Hero.css';
import { useScroll, motion, useTransform, useMotionValueEvent } from "framer-motion";
import backimg from '../../Assets/ImageSlider.png'
import { Link } from 'react-router-dom';
import img_8 from '../../Assets/8.jpg'
import img_9 from '../../Assets/9.jpg'
import img_10 from '../../Assets/10.jpg'

const Hero = () => {
  // // Create an array of image URLs to use for the slideshow
  // const [images, setImages] = useState([
  //   // 'https://lh3.googleusercontent.com/p/AF1QipOPgDs0PvqkvifhnIfVMSvVCuwJf6iNMI5PlR83=w1080-h608-p-no-v0',
  //   'https://images.unsplash.com/photo-1577017040065-650ee4d43339?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
  //   'https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
  // ]);
  
  // // Create a state variable to keep track of the current image index
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // // Function to advance to the next image in the slideshow
  // const goToNextImage = () => {
  //   const newIndex = (currentImageIndex + 1) % images.length;
  //   setCurrentImageIndex(newIndex);
  // };

  // // Start the slideshow automatically
  // useEffect(() => {
  //   const intervalId = setInterval(goToNextImage, 5000);
  //   return () => clearInterval(intervalId);
  // }, [currentImageIndex]);


  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    { image: img_8, caption: "Slide 1", description: "This is slide 1" },
    { image: img_9, caption: "Slide 2", description: "This is slide 2" },
    { image: "https://lh3.googleusercontent.com/p/AF1QipOPgDs0PvqkvifhnIfVMSvVCuwJf6iNMI5PlR83=w1080-h608-p-no-v0", caption: "Slide 3", description: "This is slide 3" },
    { image: img_10, caption: "Slide 3", description: "This is slide 3" },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
    }, 3000);
    return () => {
      clearInterval(slideInterval);
    };
  }, [currentSlide, slides.length]);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };


  return (
    <div className="hero-section">



<div className="slideshow-container">
                {slides.map((slide, index) => (
                <div
                    className={`slide-image ${index === currentSlide ? "active" : ""}`}
                    key={index}
                    style={{ backgroundImage: `url(${slide.image})` }}
                >
                  
                </div>
                ))}
            </div>






      <div className="hero-content">
        <motion.h1 
            className='heading_main'
            
            initial={{ y: -500, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1.1, type:"spring",delay:0.5 }}
        >
          Marlborough
        
        </motion.h1>
        
       <div className='reverse-div'>           
            <div>
              <div className='buttons_one'>
              {/* <Link to="https://www.clover.com/online-ordering/lalos-mexican-restauran-marlborough" target='_blank'><motion.button 
              className='button_one noHover'
              
              initial={{ x: -700, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.1, type:"spring",delay:0.5 }}
              >
                Order Now
              </motion.button></Link> */}

              <a href='tel:7745562941' target='_blank'><motion.button 
              className='button_one noHover'
              
              initial={{ x: -700, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.1, type:"spring",delay:0.5 }}
              >
                Call to Order
              </motion.button></a>

              <Link to="/marlborough/menu"><motion.button
              initial={{ x: 700, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.1, type:"spring",delay:0.5 }} 
              className='button_two noHover'
              
              >
                Explore Menu
              </motion.button></Link>
            </div>
          </div>
          <div>
              <Link to='https://www.google.com/maps/place/Lalo%E2%80%99s+Mexican+Restaurant/@42.3457017,-71.5781222,15z/data=!3m1!4b1!4m6!3m5!1s0x89e38b798db21dad:0x70e0259ad4d5b147!8m2!3d42.3456873!4d-71.5596682!16s%2Fg%2F11h2d0tfs5' style={{ color:"white" }}>
                <motion.p className='para_main'
                
                initial={{ y: 500, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 1.1, type:"spring",delay:0.5 }}
                style={{ textDecorationLine:"underline",fontWeight:"600" }}
              >
                460 Lincoln St, Marlborough, MA 01752, USA
              </motion.p></Link>
              <motion.p className='para_main'
                
                initial={{ y: 500, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 1.1, type:"spring",delay:0.5 }}
                style={{ textDecorationLine:"underline",fontWeight:"600" }}
              >
                <a href='tel:7745562941' style={{ color:'white' }}>774.556.2941</a>
              </motion.p>
            </div>
        </div>
      </div>


      <div className="indicators">
                {slides.map((slide, index) => (
                <div
                    className={`indicator ${index === currentSlide ? "active" : ""}`}
                    key={index}
                    onClick={() => handleIndicatorClick(index)}
                />
                ))}
            </div>            


    </div>
  );
};

export default Hero;

import React from 'react';
import './Social.css';
import insta_1 from "../../Assets/insta_1.png"
import insta_2 from "../../Assets/insta_2.png"
import insta_3 from "../../Assets/insta_3.png"
import insta_4 from "../../Assets/insta_4.png"
import insta_5 from "../../Assets/insta_5.png"
import insta_6 from "../../Assets/insta_4.png"
import insta_7 from "../../Assets/insta_1.png"
import insta_8 from "../../Assets/insta_2.png"
import { Link } from 'react-router-dom';


import { ElfsightWidget } from 'react-elfsight-widget';


const FollowUs = () => {
  return (
     <div className="follow-container">
      <h1 className="follow-heading">The 'Gram</h1>
      
       <div className='follow-wrap'>
      <ElfsightWidget widgetID="dfe73b04-09ee-43e0-8081-bf3ade331614" />
      </div> 
       {/* <h1 className="follow-heading">The 'Gram</h1>
       <div className='photo-cover'>
      
         <div className="photo-container-n">
           <Link to="https://www.instagram.com/lalosmexican/"><img src={insta_1} alt="Placeholder 1" className="photo" /></Link>
          <Link to="https://www.instagram.com/lalosmexican/"><img src={insta_2} alt="Placeholder 2" className="photo" /></Link>
          <Link to="https://www.instagram.com/lalosmexican/"><img src={insta_3} alt="Placeholder 3" className="photo" /></Link>
          <Link to="https://www.instagram.com/lalosmexican/"><img src={insta_4} alt="Placeholder 4" className="photo" /></Link>
          <Link to="https://www.instagram.com/lalosmexican/"><img src={insta_5} alt="Placeholder 5" className="photo" /></Link>
          <Link to="https://www.instagram.com/lalosmexican/"><img src={insta_6} alt="Placeholder 6" className="photo" /></Link>
          <Link to="https://www.instagram.com/lalosmexican/"><img src={insta_7} alt="Placeholder 7" className="photo" /></Link>
          <Link to="https://www.instagram.com/lalosmexican/"><img src={insta_8} alt="Placeholder 8" className="photo" /></Link> 
         </div>
      </div> */}
    </div> 
  );
};

export default FollowUs;

import React from "react";
import "./Features.css"
import icon_one from "../../../Assets/fea_icon_one.svg" 
import icon_two from "../../../Assets/fea_icon_two.svg" 
import icon_three from "../../../Assets/fea_icon_three.svg" 
import { Link } from "react-router-dom";

const Section = () => {
  return (
    <div className="section-container">
      <div className="column">
        <h2 className="fea-head">Book your catering service in 3 steps</h2>
      </div>
      <div className="column">
        <div className="icons-row">
          <div className="icon-container">
            <img className="icon fas fa-icon1" src={icon_one} />
            <p>Schedule an appointment for your event or Choose from Quick Catering Options</p>
          </div>
          <div className="icon-container">
            <img className="icon fas fa-icon2" src={icon_two}/>
            <p>We contact you to review and Finalize the order </p>
          </div>
          <div className="icon-container">
            <img className="icon fas fa-icon3" src={icon_three}/>
            <p>Delicious Food at your venue</p>
          </div>
        </div>
      </div>
        <div>
        <Link to='/hudson/contact-us' style={{ textDecoration:'none', color:"black" }}><h2 className="btm-btn">Schedule an Appointment</h2></Link>
       </div>
    </div>
  );
};

export default Section;

import React,{ useState, useEffect } from 'react';
import './Footer.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import {AiFillInstagram} from 'react-icons/ai'
import Logo from "../../Assets/lalologo2.png"
import Divider from "../../Assets/line.png"
import { Link } from 'react-router-dom';

const Footer = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  return (
   
    <div className="footer-container">
       {isMobile && 
       <div className='reverse-pa'>
           <div className='footer-cover'>
           <div className="logo-column">
             <img src={Logo} alt="Logo" className="logo-image" />
           </div>
           <div className="address-column">
            <Link to='https://www.google.com/maps/place/Lalos+Mexican+restaurant/@42.3914668,-71.5677837,17z/data=!3m1!4b1!4m6!3m5!1s0x89e38d63464c04dd:0x1ce236cfccb26376!8m2!3d42.3914629!4d-71.5652088!16s%2Fg%2F11rwvg3fbl'  style={{ color:"black" }}><p style={{ textDecorationLine:"underline",cursor:"pointer" }}>158 Main St, Hudson, MA 01749, USA</p></Link>
            <p style={{ textDecorationLine:"underline",cursor:"pointer" }}><a href='tel:9783107246' style={{ color:'black' }}>978.310.7246</a></p>
             <p>Mon - Thu : 11:00 AM to 9:00PM </p>
             <p>Fri - Sat : 11:00AM to 10:00PM</p>
             <p>Sun : 11:00AM to 8:00PM</p>
           </div>
           <div>
             <img src={Divider} alt="div" className="div-image" />
           </div>
         </div>
 
       <div className='footer-main-2'>
         <div className='footer-cover-2'>
             <div className="location-column">
               <h3 className='h33'>LOCATIONS</h3>
               <ul className='ull'>
                 <Link to="/hudson" style={{ textDecoration:"none" }}> <li className='lii hudd'>Hudson, MA</li></Link>
                 <Link to="/marlborough" style={{ textDecoration:"none" }}><li className='lii marll'>Marlborough, MA</li></Link>
                 
               </ul>
             </div>
             <div className="about-us-column">
               <h3 className='h33'>ABOUT US</h3>
               <ul className='ull'>
               <Link to="/hudson/about-us" style={{ textDecoration:"none",color:"black" }}> <li className='lii marll'>About</li></Link>
               <Link to = "/hudson/catering" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>Catering</li></Link>
               <Link to="/hudson/contact-us" style={{ textDecoration:"none",color:"black" }}> <li className='lii marll'>Contact</li></Link>
               <Link to="/hudson/privacy-policy" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>Privacy Policy</li></Link>
               </ul>
             </div>
           </div>
 
 
           <div className='footer-cover-3'>
             <div className="socials-column">
               <h3 className='h33'>SOCIAL MEDIA</h3>
               <ul className='ull'>
               <Link to='https://www.facebook.com/profile.php?id=100051069027052&mibextid=ZbWKwL' style={{ textDecoration:"none",color:"black" }} target='_blank'><li className='lii marll'><FaFacebook className='svg_icon_fc'/> Facebook</li></Link>
               <Link to='https://instagram.com/lalosmexican' style={{ textDecoration:"none",color:"black" }} target='_blank'><li className='lii marll'><AiFillInstagram className='svg_icon' /> Instagram</li></Link> 
               </ul>
             </div>
           </div>
         </div>
        </div>
         }
      {!isMobile && 
      
      <div className='footer-cover'>
        <div className="logo-column">
          <img src={Logo} alt="Logo" className="logo-image2" />
        </div>
        <div className="address-column">
        <Link to='https://www.google.com/maps/place/Lalos+Mexican+restaurant/@42.3914668,-71.5677837,17z/data=!3m1!4b1!4m6!3m5!1s0x89e38d63464c04dd:0x1ce236cfccb26376!8m2!3d42.3914629!4d-71.5652088!16s%2Fg%2F11rwvg3fbl' style={{ color:"black" }}><p style={{ textDecorationLine:"underline",cursor:"pointer" }}>158 Main St, Hudson, MA 01749, USA</p></Link>
          <p style={{ textDecorationLine:"underline",cursor:"pointer" }}><a href='tel:9783107246' style={{ color:'black' }}>978.310.7246</a></p>
          <p>Mon - Thu : 11:00 AM to 9:00PM </p>
          <p>Fri - Sat : 11:00AM to 10:00PM</p>
          <p>Sun : 11:00AM to 8:00PM</p>
        </div>
        <div>
          <img src={Divider} alt="div" className="div-image" />
        </div>
        <div className="location-column">
          <h3 className='h33'>LOCATIONS</h3>
          <ul className='ull'>
            <Link to="/hudson" style={{ textDecoration:"none" }}> <li className='lii hudd'>Hudson, MA</li></Link>
            <Link to="/marlborough" style={{ textDecoration:"none" }}><li className='lii marll'>Marlborough, MA</li></Link>
            
          </ul>
        </div>
        <div className="about-us-column">
          <h3 className='h33'>ABOUT US</h3>
          <ul className='ull'>
          <Link to="/hudson/about-us" style={{ textDecoration:"none",color:"black" }}> <li className='lii marll'>About</li></Link>
          <Link to = "/hudson/catering" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>Catering</li></Link>
           <Link to="/hudson/contact-us" style={{ textDecoration:"none",color:"black" }}> <li className='lii marll'>Contact</li></Link>
           <Link to ="/hudson/privacy-policy" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>Privacy Policy</li></Link> 
            
          </ul>
        </div>
        <div className="socials-column">
          <h3 className='h33'>SOCIAL MEDIA</h3>
          <ul className='ull'>
          <Link to='https://www.facebook.com/profile.php?id=100051069027052&mibextid=ZbWKwL' style={{ textDecoration:"none",color:"black" }} target='_blank'><li className='lii marll'><FaFacebook className='svg_icon_fc'/> Facebook</li></Link>
         <Link to='https://instagram.com/lalosmexican' style={{ textDecoration:"none",color:"black" }}><li className='lii marll' target='_blank'><AiFillInstagram className='svg_icon' /> Instagram</li></Link> 
          </ul>
        </div>
      </div>
      
      }
       
      
    </div>
  );
};

export default Footer;

import React,{ useState } from 'react'
import './ResData.css'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

const ResData = () => {
const [counterOn, setCounterOn] = useState(false)


  return (
    <div className='data-container'>
            <div className='ress-col-1'>
                <h1 className='ress-head-2'>
                    Our Reach
                </h1>
                <div className='ress-items'>
                        <div>
                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}/>
                            {/* <CountUp start={0} end = {4800} duration={2} delay={0} /> */}
                            <h1 className='ress-itm-head'>{counterOn && <CountUp start={0} end = {4800} duration={2} delay={0} />}+</h1>
                            <p className='ress-itm'>Happy Customers</p>
                        </div>
                        <div>
                            <h1 className='ress-itm-head'>{counterOn && <CountUp start={0} end = {104} duration={2} delay={0} />}+</h1>
                            <p className='ress-itm'>Events Catered</p>
                        </div>
                        <div>
                            <h1 className='ress-itm-head'>{counterOn && <CountUp start={0} end = {5} duration={2} delay={0} />}+</h1>
                            <p className='ress-itm'>Years of Work</p>
                        </div>
                </div>
            </div>
    </div>
  )
}

export default ResData
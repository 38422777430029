import React,{ useState, useEffect } from "react";
import "./Privacy.css";
import NavbarP from "../Navbar/NavbarP";
import FooterD from "../Footer/FooterD";
import { IoIosArrowDown } from 'react-icons/io'

function Collapsible() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [collapsed, setCollapsed] = useState(false);
  const [collapsed2, setCollapsed2] = useState(false);
  const [collapsed3, setCollapsed3] = useState(false);
  const [collapsed4, setCollapsed4] = useState(false);
  const [collapsed5, setCollapsed5] = useState(false);

  const [collapsed6, setCollapsed6] = useState(false);
  const [collapsed7, setCollapsed7] = useState(false);
  const [collapsed8, setCollapsed8] = useState(false);
  const [collapsed9, setCollapsed9] = useState(false);
  const [collapsed10, setCollapsed10] = useState(false);

  const [collapsed11, setCollapsed11] = useState(false);
  const [collapsed12, setCollapsed12] = useState(false);
  const [collapsed13, setCollapsed13] = useState(false);
  const [collapsed14, setCollapsed14] = useState(false);
  const [collapsed15, setCollapsed15] = useState(false);

  const [collapsed16, setCollapsed16] = useState(false);
  const [collapsed17, setCollapsed17] = useState(false);
  const [collapsed18, setCollapsed18] = useState(false);


  const [isMobile, setIsMobile] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const toggleCollapsed2 = () => {
    setCollapsed2(!collapsed2);
  };
  const toggleCollapsed3 = () => {
    setCollapsed3(!collapsed3);
  };
  const toggleCollapsed4 = () => {
    setCollapsed4(!collapsed4);
  };
  const toggleCollapsed5 = () => {
    setCollapsed5(!collapsed5);
  };


  const toggleCollapsed6 = () => {
    setCollapsed6(!collapsed6);
  };
  const toggleCollapsed7 = () => {
    setCollapsed7(!collapsed7);
  };
  const toggleCollapsed8 = () => {
    setCollapsed8(!collapsed8);
  };
  const toggleCollapsed9 = () => {
    setCollapsed9(!collapsed9);
  };
  const toggleCollapsed10 = () => {
    setCollapsed10(!collapsed10);
  };


  const toggleCollapsed11 = () => {
    setCollapsed11(!collapsed11);
  };
  const toggleCollapsed12 = () => {
    setCollapsed12(!collapsed12);
  };
  const toggleCollapsed13 = () => {
    setCollapsed13(!collapsed13);
  };
  const toggleCollapsed14 = () => {
    setCollapsed14(!collapsed14);
  };
  const toggleCollapsed15 = () => {
    setCollapsed15(!collapsed15);
  };


  const toggleCollapsed16 = () => {
    setCollapsed16(!collapsed16);
  };
  const toggleCollapsed17 = () => {
    setCollapsed17(!collapsed17);
  };
  const toggleCollapsed18 = () => {
    setCollapsed18(!collapsed18);
  };

  const doNothing = () => {

  }
  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="privacy-container">
    <NavbarP/>
    <div className="privacy-wrap-w">
      <h1 className="pri-main-head">PRIVACY POLICY</h1>


      <div className="wrap-main">
        <p style={{ color: '#575757'}}>Effective date: 04/10/2023</p>
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed : doNothing}>Introduction<IoIosArrowDown className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">Welcome to Lalos Mexican Restaurant.</p>
          <p className="privacy-para">Lalos Mexican Restaurant (“us”, “we”, or “our”) operates https://lalosmexican.com (hereinafter referred to as “Service”).</p>
          <p className="privacy-para">Our Privacy Policy governs your visit to https://lalosmexican.com, and explains how we collect, safeguard and disclose information that results from your use of our Service. </p>
          <p className="privacy-para">We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
          <p className="privacy-para">Our Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (“agreement”).</p>
        </div>
      </div>

      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed2 : doNothing}>Definitions<IoIosArrowDown className="arrow-priv"/></h2>
        <div className={isMobile ? `collapsible-content ${collapsed2 ? "collapsed" : ""}` : ''}>
        <p className="privacy-para"><span style={{ fontWeight:"bold" }}>PERSONAL DATA</span> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
        <p className="privacy-para"><span style={{ fontWeight:"bold" }}>USAGE DATA</span> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
        <p className="privacy-para"><span style={{ fontWeight:"bold" }}>COOKIES</span> are small files stored on your device (computer or mobile device).</p>
        <p className="privacy-para"><span style={{ fontWeight:"bold" }}>DATA CONTROLLER</span> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
        <p className="privacy-para"><span style={{ fontWeight:"bold" }}>DATA PROCESSORS (OR SERVICE PROVIDERS)</span> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
         </p>
        <p className="privacy-para"><span style={{ fontWeight:"bold" }}>DATA SUBJECT</span> is any living individual who is the subject of Personal Data.
         </p>
        <p className="privacy-para"><span style={{ fontWeight:"bold" }}>THE USER</span> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
         </p>   
      </div>
      </div>
      

      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed3 : doNothing}>Information Collection and Use<IoIosArrowDown className="arrow-priv"/></h2>
        <div className={ isMobile ? `collapsible-content ${collapsed3 ? "collapsed" : ""}` : ''}>
         <p className="privacy-para">We collect several different types of information for various purposes to provide and improve our Service to you.</p>
        </div>
      </div>

        <div className="wrap-main">
          <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed18 : doNothing}>Types of Data Collected<IoIosArrowDown className="arrow-priv"/></h2>
          <div className={ isMobile ? `collapsible-content ${collapsed18 ? "collapsed" : ""}` : ''}>
            <h3 className="privacy-head">Personal Data</h3>
            <p className="privacy-para">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
            <p className="privacy-para">1. Email address</p>
            <p className="privacy-para">2. First name and last name</p>
            <p className="privacy-para">3. Phone number</p>
            <p className="privacy-para">4. Address, State, Province, ZIP/Postal code, City</p>
            <p className="privacy-para">5. Cookies and Usage Data</p>
            <p className="privacy-para">We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by emailing at support@lalosmexican.com.</p>
            <h3 className="privacy-head">Usage Data</h3>
            <p className="privacy-para">We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a mobile device (“Usage Data”).</p>
            <p className="privacy-para">This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p className="privacy-para">When you access Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>
            <h3 className="privacy-head">Location Data</h3>
            <p className="privacy-para">We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Servic</p>
            <p className="privacy-para">You can enable or disable location services when you use our Service at any time by way of your device settings.</p>
            <h3 className="privacy-head">Tracking Cookies Data</h3>
            <p className="privacy-para">We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
            <p className="privacy-para">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>
            <p className="privacy-para">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <h3 className="privacy-head">Examples of Cookies we use:</h3>
            <p className="privacy-para">1. Session Cookies: We use Session Cookies to operate our Service.</p>
            <p className="privacy-para">2. Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</p>
            <p className="privacy-para">3. Security Cookies: We use Security Cookies for security purposes.</p>
            <p className="privacy-para">4. Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</p>
            <h3 className="privacy-head">Other Data</h3>
            <p className="privacy-para">While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, non-disclosure agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.</p>
          </div>
        </div>








      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed4 : doNothing}>Use of Data <IoIosArrowDown className="arrow-priv"/></h2>
        <div className={ isMobile ? `collapsible-content ${collapsed4 ? "collapsed" : ""}` : ''}>
          <h3 className="privacy-head">Lalos Mexican Restaurant uses the collected data for various purposes:</h3>
          <p className="privacy-para">1. to provide and maintain our Service; </p>
          <p className="privacy-para">2. to notify you about changes to our Service; </p>
          <p className="privacy-para">3. to allow you to participate in interactive features of our Service when you choose to do so; </p>
          <p className="privacy-para">4. to provide customer support; </p>
          <p className="privacy-para">5. to gather analysis or valuable information so that we can improve our Service;</p>
          <p className="privacy-para">6. to monitor the usage of our Service;</p>
          <p className="privacy-para">7. to detect, prevent and address technical issues;</p>
          <p className="privacy-para">8. to fulfill any other purpose for which you provide it;</p>
          <p className="privacy-para">9. to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</p>
          <p className="privacy-para">10. to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</p>
          <p className="privacy-para">11. to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</p>
          <p className="privacy-para">12. in any other way we may describe when you provide the information;</p>
          <p className="privacy-para">13. for any other purpose with your consent.</p>
        </div>
      </div>

      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed5 : doNothing}>Retention of Data<IoIosArrowDown  className="arrow-priv"/></h2>
        <div className={ isMobile ? `collapsible-content ${collapsed5 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
          <p className="privacy-para">We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to 
          strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
        </div>
      </div> 


      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed6 : doNothing}>Transfer of Data <IoIosArrowDown  className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed6 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
          <p className="privacy-para">If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</p>
          <p className="privacy-para">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
          <p className="privacy-para">Lalos Mexican Restaurant will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information</p>
        </div>
      </div> 


      <div className="wrap-main">
        <h2  className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed7 : doNothing}>Disclosure of Data.<IoIosArrowDown  className="arrow-priv"/></h2>
        <div className={ isMobile ? `collapsible-content ${collapsed7 ? "collapsed" : ""}` : ''}>
          <h3 className="privacy-para">We may disclose personal information that we collect, or you provide:</h3>
          <h3 className="privacy-head">Disclosure for Law Enforcement.</h3>
          <p className="privacy-para">Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p>
          <h3 className="privacy-head">Business Transaction.</h3>
          <p className="privacy-para">If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</p>
          <h3 className="privacy-head">Other cases. We may disclose your information also:</h3>
          <p className="privacy-para">1. to our subsidiaries and affiliates;</p>
          <p className="privacy-para">2. to contractors, service providers, and other third parties we use to support our business;</p>
          <p className="privacy-para">3. to fulfil the purpose for which you provide it;</p>
          <p className="privacy-para">4. for the purpose of including your company’s logo on our website;</p>
          <p className="privacy-para">5. for any other purpose disclosed by us when you provide the information;</p>
        </div>
      </div> 


      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed8 : doNothing}>Security of Data<IoIosArrowDown  className="arrow-priv"/></h2>
        <div className={ isMobile ? `collapsible-content ${collapsed8 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
        </div>
      </div>


      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed9 : doNothing}>Service Providers<IoIosArrowDown  className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed9 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
          <p className="privacy-para">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
        </div>
      </div> 

      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed10 : doNothing}>Analytics<IoIosArrowDown  className="arrow-priv"/></h2>
        <div className={ isMobile ? `collapsible-content ${collapsed10 ? "collapsed" : ""}` : ''}>
          <h3 className="privacy-head">We may use third-party Service Providers to monitor and analyze the use of our Service.</h3>
          <h2 className="privacy-head">Google Analytics</h2>
          <p className="privacy-para">Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network</p>
          <p className="privacy-para">For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</p>
          <p className="privacy-para">We also encourage you to review the Google's policy for safeguarding your data: https://support.google.com/analytics/answer/6004245.</p>
          <h3 className="privacy-head">Cloudflare analytics</h3>
          <p className="privacy-para">Cloudflare analytics is a web analytics service operated by Cloudflare Inc. Read the Privacy Policy here: https://www.cloudflare.com/privacypolicy/ </p>
          <h3 className="privacy-head">Segment.io</h3>
          <p className="privacy-para">Segment.io is a web traffic analysis tool. You can read the Privacy Policy for Segment.io here: https://segment.com/legal/privacy/.</p>
          <h3 className="privacy-head">Mixpanel</h3>
          <p className="privacy-para">Mixpanel is provided by Mixpanel Inc.</p>
          <p className="privacy-para">You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: https://mixpanel.com/optout/</p>
          <p className="privacy-para">For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: https://mixpanel.com/terms/</p>
          <h3 className="privacy-head">Unity Analytics</h3>
          <p className="privacy-para">Unity Analytics is provided by Unity Technologies.</p>
          <p className="privacy-para">For more information on what type of information Unity Analytics collects, please visit their Privacy Policy page: https://unity3d.com/legal/privacy-policy</p>
        </div>
      </div>


      
  <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed11 : doNothing}>CI/CD tools<IoIosArrowDown  className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed11 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">We may use third-party Service Providers to automate the development process of our Service. </p>
          <h3 className="privacy-head">GitHub</h3>
          <p className="privacy-para">GitHub is provided by GitHub, Inc.</p>
          <p className="privacy-para">GitHub is a development platform to host and review code, manage projects, and build software.</p>
          <p className="privacy-para">For more information on what data GitHub collects for what purpose and how the protection of the data is ensured, please visit GitHub Privacy Policy page: https://help.github.com/en/articles/github-privacy-statement.</p>
          <h3 className="privacy-head">GitLab CI/CD</h3>
          <p className="privacy-para">GitLab CI/CD is provided by GitLab, Inc.</p>
          <p className="privacy-para">GitLab CI (Continuous Integration) service is a part of GitLab that build and test the software whenever developer pushes code to application. </p>
          <p className="privacy-para">GitLab CD (Continuous Deployment) is a software service that places the changes of every code in the production which results in every day deployment of production.</p>
          <p className="privacy-para">For more information on what data GitLab CI/CD collects for what purpose and how the protection of the data is ensured, please visit GitLab CI/CD Privacy Policy page: https://about.gitlab.com/privacy/.</p>
        </div>
      </div> 
     

      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed12 : doNothing}>Behavioral Remarketing<IoIosArrowDown  className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed12 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">Lalos Mexican Restaurant uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.</p>
          <h3 className="privacy-head">Google Ads (AdWords)</h3>
          <p className="privacy-para">Google Ads (AdWords) remarketing service is provided by Google Inc.</p>
          <p className="privacy-para">You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads</p>
          <p className="privacy-para">Google also recommends installing the Google Analytics Opt-out Browser Add-on – https://tools.google.com/dlpage/gaoptout – for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
          <p className="privacy-para">For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</p>
          <h3 className="privacy-head">Twitter</h3>
          <p className="privacy-para">Twitter remarketing service is provided by Twitter Inc.</p>
          <p className="privacy-para">You can opt-out from Twitter's interest-based ads by following their instructions: https://support.twitter.com/articles/20170405</p>
          <p className="privacy-para">You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: https://twitter.com/privacy</p>
          <h3 className="privacy-head">Facebook</h3>
          <p className="privacy-para">Facebook remarketing service is provided by Facebook Inc.</p>
          <p className="privacy-para">You can learn more about interest-based advertising from Facebook by visiting this page: https://www.facebook.com/help/164968693837950</p>
          <p className="privacy-para">To opt-out from Facebook's interest-based ads, follow these instructions from Facebook: https://www.facebook.com/help/568137493302217</p>
          <p className="privacy-para">Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt-out using your mobile device settings.</p>
          <p className="privacy-para">For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: https://www.facebook.com/privacy/explanation</p>
        </div>
      </div> 


      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed13 : doNothing}>Payments<IoIosArrowDown  className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed13 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
          <p className="privacy-para">We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
          <p className="privacy-para">The payment processors we work with are:</p>
          <h3 className="privacy-head">PayPal or Braintree:</h3>
          <p className="privacy-para">Their Privacy Policy can be viewed at https://www.paypal.com/webapps/mpp/ua/privacy-full</p>
          <h3 className="privacy-head">Apple Store In-App Payments:</h3>
          <p className="privacy-para">Their Privacy Policy can be viewed at: https://www.apple.com/legal/privacy/en-ww/ / https://support.apple.com/en-us/HT203027</p>
          <h3 className="privacy-head">Google Play In-App Payments:</h3>
          <p className="privacy-para">Their Privacy Policy can be viewed at: https://policies.google.com/privacy?hl=en&gl=us / https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en </p>
          <h3 className="privacy-head">Stripe:</h3>
          <p className="privacy-para">Their Privacy Policy can be viewed at: https://stripe.com/us/privacy</p>
          <h3 className="privacy-head">2Checkout:</h3>
          <p className="privacy-para">Their Privacy Policy can be viewed at: https://www.2checkout.com/legal/privacy/ </p>
          <h3 className="privacy-head">Go Cardless:</h3>
          <p className="privacy-para">Their Privacy Policy can be viewed at: https://gocardless.com/legal/privacy </p>
          <h3 className="privacy-head">Square:</h3>
          <p className="privacy-para">Their Privacy Policy can be viewed at: https://squareup.com/us/en/legal/general/privacy </p>
          <h3 className="privacy-head">Verifone:</h3>
          <p className="privacy-para">Their Privacy Policy can be viewed at: https://www.verifone.com/en/privacy </p>
        </div>
      </div> 


      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed14 : doNothing}>Links to Other Sites<IoIosArrowDown  className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed14 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
          <p className="privacy-para">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
        </div>
      </div> 

        <div className="wrap-main">
          <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed15 : doNothing}>Children's Privacy<IoIosArrowDown  className="arrow-priv"/></h2>
          <div  className={ isMobile ? `collapsible-content ${collapsed15 ? "collapsed" : ""}` : ''}>
            <p className="privacy-para">Our Services are not intended for use by children under the age of 13 (“Children”). </p>
            <p className="privacy-para">We do not knowingly collect personally identifiable information from Children under 13. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p> 
          </div> 
      </div> 


      <div className="wrap-main">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed16 : doNothing}>Changes to This Privacy Policy<IoIosArrowDown  className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed16 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          <p className="privacy-para">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
          <p className="privacy-para">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        </div>
      </div> 


      <div className="wrap-main mrgn">
        <h2 className={`privacy-head ${isMobile ? 'collapsible-header' : ''}`}   onClick={isMobile ? toggleCollapsed17 : doNothing}>Contact Us<IoIosArrowDown  className="arrow-priv"/></h2>
        <div  className={ isMobile ? `collapsible-content ${collapsed17 ? "collapsed" : ""}` : ''}>
          <p className="privacy-para">If you have any questions about this Privacy Policy, please contact us:</p>
          <p className="privacy-para">By email: support@lalosmexican.com.</p>
        </div>
      </div> 





    </div>
<FooterD/>
  </div>
  );
}

export default Collapsible;






//  <div className="wrap-main">
//         <h3 className="privacy-head"></h3>
//         <p className="privacy-para"></p>
//         <p className="privacy-para"></p>
//         <p className="privacy-para"></p>
//         <p className="privacy-para"></p>
//         <p className="privacy-para"></p>
//       </div> 

  {/* <div className="privacy-wrap">
            <div className="collapsible-container">
            <h1 className="collapsible-header" onClick={toggleCollapsed}>
                Collapsible Title
            </h1>
            <div className={`collapsible-content ${collapsed ? "collapsed" : ""}`}>
                <p>First paragraph</p>
                <p>Second paragraph</p>
                <ul>
                <li>List item 1</li>
                <li>List item 2</li>
                <li>List item 3</li>
                </ul>
            </div>
            </div>
            <div className="collapsible-container">
            <h1 className="collapsible-header" onClick={toggleCollapsed}>
            Collapsible Title
            </h1>
            <div className={`collapsible-content ${collapsed ? "collapsed" : ""}`}>
            <p>First paragraph</p>
            <p>Second paragraph</p>
            <ul>
                <li>List item 1</li>
                <li>List item 2</li>
                <li>List item 3</li>
            </ul>
            </div>
            </div>
            <div className="collapsible-container">
            <h1 className="collapsible-header" onClick={toggleCollapsed}>
            Collapsible Title
            </h1>
            <div className={`collapsible-content ${collapsed ? "collapsed" : ""}`}>
            <p>First paragraph</p>
            <p>Second paragraph</p>
            <ul>
                <li>List item 1</li>
                <li>List item 2</li>
                <li>List item 3</li>
            </ul>
            </div>
        </div>
    </div> */}
import React,{useState, useEffect} from 'react';
import './Footer.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import Logo from "../../Assets/lalologo2.png"
import Divider from "../../Assets/line.png"
import {AiFillInstagram} from 'react-icons/ai'
import { Link } from 'react-router-dom';

const Footer = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
  
<div className="footer-container">
{isMobile && 
<div className='reverse-pa'>
  <div className='footer-cover'>
  <div className="logo-column">
    <img src={Logo} alt="Logo" className="logo-image" />
  </div>
  <div className="address-column">
  <Link to='https://www.google.com/maps/place/Lalo%E2%80%99s+Mexican+Restaurant/@42.3457017,-71.5781222,15z/data=!3m1!4b1!4m6!3m5!1s0x89e38b798db21dad:0x70e0259ad4d5b147!8m2!3d42.3456873!4d-71.5596682!16s%2Fg%2F11h2d0tfs5' style={{ color:"black" }}><p style={{ textDecorationLine:"underline", cursor:"pointer" }}>460 Lincoln St, Marlborough, MA 01752, USA</p></Link>
  <p style={{ textDecorationLine:"underline", cursor:"pointer" }}><a href='tel:7745562941' style={{ color:'black' }}>774.556.2941</a></p>
    <p>Mon - Thu : 11:00 AM to 9:00PM </p>
    <p>Fri - Sat : 11:00AM to 10:00PM</p>
    <p>Sun : 11:00AM to 8:00PM</p>
  </div>
  <div>
    <img src={Divider} alt="div" className="div-image" />
  </div>
</div>

<div className='footer-main-2'>
<div className='footer-cover-2'>
    <div className="location-column">
      <h3 className='h33'>LOCATIONS</h3>
      <ul className='ull'>
      <Link to="/hudson" style={{ textDecoration:"none" }}><li className='lii huddo'>Hudson, MA</li></Link>
            <Link to="/marlborough" style={{ textDecoration:"none" }}><li className='lii marllo'>Marlborough, MA</li></Link>
        
      </ul>
    </div>
    <div className="about-us-column">
      <h3 className='h33'>ABOUT US</h3>
      <ul className='ull'>
      <Link to="/marlborough/about-us" style={{ textDecoration:"none",color:"black" }}> <li className='lii marll'>About</li></Link>
      <Link to = "/marlborough/catering" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>Catering</li></Link>
      <Link to="/marlborough/contact-us" style={{ textDecoration:"none",color:"black" }}> <li className='lii marll'>Contact</li></Link>
      <Link to="/marlborough/privacy-policy" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>Privacy Policy</li></Link>
      </ul>
    </div>
  </div>


  <div className='footer-cover-3'>
    <div className="socials-column">
      <h3 className='h33'>SOCIAL MEDIA</h3>
      <ul className='ull'>
      <Link to='https://www.facebook.com/profile.php?id=100051069027052&mibextid=ZbWKwL' style={{ textDecoration:"none",color:"black" }} target='_blank'><li className='lii marll'><FaFacebook className='svg_icon_fc'/> Facebook</li></Link>
         <Link to='https://instagram.com/lalosmexican' style={{ textDecoration:"none",color:"black" }} target='_blank'><li className='lii marll'><AiFillInstagram className='svg_icon' /> Instagram</li></Link> 
      </ul>
    </div>
  </div>
</div>
</div>
}
      {
        !isMobile &&
        <div className='footer-cover'>
        <div className="logo-column">
          <img src={Logo} alt="Logo" className="logo-image2" />
        </div>
        <div className="address-column">
        <Link to='https://www.google.com/maps/place/Lalo%E2%80%99s+Mexican+Restaurant/@42.3457017,-71.5781222,15z/data=!3m1!4b1!4m6!3m5!1s0x89e38b798db21dad:0x70e0259ad4d5b147!8m2!3d42.3456873!4d-71.5596682!16s%2Fg%2F11h2d0tfs5' style={{ color:"black" }}><p style={{ textDecorationLine:"underline", cursor:"pointer" }}>460 Lincoln St, Marlborough, MA 01752, USA</p></Link>
        <p style={{ textDecorationLine:"underline", cursor:"pointer" }}><a href='tel:7745562941' style={{ color:'black' }}>774.556.2941</a></p>
          <p>Mon - Thu : 11:00 AM to 9:00PM </p>
          <p>Fri - Sat : 11:00AM to 10:00PM</p>
          <p>Sun : 11:00AM to 8:00PM</p>
        </div>
        <div>
          <img src={Divider} alt="div" className="div-image" />
        </div>
        <div className="location-column">
          <h3 className='h33'>LOCATIONS</h3>
          <ul className='ull'>
          <Link to="/hudson" style={{ textDecoration:"none" }}><li className='lii huddo'>Hudson, MA</li></Link>
            <Link to="/marlborough" style={{ textDecoration:"none" }}><li className='lii marllo'>Marlborough, MA</li></Link>
            
          </ul>
        </div>
        <div className="about-us-column">
          <h3 className='h33'>ABOUT US</h3>
          <ul className='ull'>
            <Link to = "/marlborough/about-us" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>About</li></Link>
            <Link to = "/marlborough/catering" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>Catering</li></Link>
           <Link to = "/marlborough/contact-us" style={{ textDecoration:"none",color:"black" }}> <li className='lii marll'>Contact</li></Link>
            <Link to ="/marlborough/privacy-policy" style={{ textDecoration:"none",color:"black" }}><li className='lii marll'>Privacy Policy</li></Link>
          </ul>
        </div>
        <div className="socials-column">
          <h3 className='h33'>SOCIAL MEDIA</h3>
          <ul className='ull'>
          <Link to='https://www.facebook.com/profile.php?id=100051069027052&mibextid=ZbWKwL' style={{ textDecoration:"none",color:"black" }} target='_blank'><li className='lii marll'><FaFacebook className='svg_icon_fc'/> Facebook</li></Link>
         <Link to='https://instagram.com/lalosmexican' style={{ textDecoration:"none",color:"black" }} target='_blank'><li className='lii marll'><AiFillInstagram className='svg_icon' /> Instagram</li></Link> 
          </ul>
        </div>
      </div>
      }


</div>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import './Hero.css';
import { useScroll, motion, useTransform, useMotionValueEvent } from "framer-motion";
import backimg from '../../Assets/ImageSlider.png'
import herod_img from '../../Assets/herod.png'
import { Link } from 'react-router-dom';
import img_1 from '../../Assets/1.jpg'
import img_2 from '../../Assets/2.jpg'
import img_3 from '../../Assets/3.jpg'
import img_4 from '../../Assets/4.jpg'

const Herod = () => {
  // // Create an array of image URLs to use for the slideshow
  // const [images, setImages] = useState([
  //   herod_img
    
  // ]);
  
  // // Create a state variable to keep track of the current image index
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // // Function to advance to the next image in the slideshow
  // const goToNextImage = () => {
  //   const newIndex = (currentImageIndex + 1) % images.length;
  //   setCurrentImageIndex(newIndex);
  // };

  // // Start the slideshow automatically
  // useEffect(() => {
  //   const intervalId = setInterval(goToNextImage, 5000);
  //   return () => clearInterval(intervalId);
  // }, [currentImageIndex]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    { image: img_1, caption: "Slide 1", description: "This is slide 1" },
    { image: img_2, caption: "Slide 2", description: "This is slide 2" },
    { image: img_3, caption: "Slide 3", description: "This is slide 3" },
    { image: img_4, caption: "Slide 3", description: "This is slide 3" },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
    }, 3000);
    return () => {
      clearInterval(slideInterval);
    };
  }, [currentSlide, slides.length]);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="hero-section">




            <div className="slideshow-container">
                {slides.map((slide, index) => (
                <div
                    className={`slide-image ${index === currentSlide ? "active" : ""}`}
                    key={index}
                    style={{ backgroundImage: `url(${slide.image})` }}
                >
                </div>
                ))}
            </div>



      <div className="hero-contentD">
       
        <div className='buttons_oneD'>
        <Link to = "/hudson">
        <motion.button 
        className='button_one noHover right-mar'
        
         initial={{ x: -700, opacity: 0 }}
         animate={{ x: 0, opacity: 1 }}
         transition={{ duration: 1.1, type:"spring",delay:0.5 }}
         style={{ fontFamily:"caveat",fontSize:"2.5em" }}
        >
          Hudson
        </motion.button>
        </Link>
        <Link to = "/marlborough">
        <motion.button
        initial={{ x: 700, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.1, type:"spring",delay:0.5 }} 
        className='button_one noHover left-mar'
        style={{ fontFamily:"caveat",fontSize:"2.5em" }}
         
        >
          Marlborough
        </motion.button>
        </Link>
      </div>
       
      </div>

            <div className="indicators">
                {slides.map((slide, index) => (
                <div
                    className={`indicator ${index === currentSlide ? "active" : ""}`}
                    key={index}
                    onClick={() => handleIndicatorClick(index)}
                />
                ))}
            </div>  

    </div>
  );
};

export default Herod;

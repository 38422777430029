import React, { useState, useEffect } from 'react';
import './ResHero.css';
 import slide1 from '../../../Assets/Catering_banner_image_1.png';
 import slide2 from '../../../Assets/Catering_banner_image_2.png';
 import slide3 from '../../../Assets/Catering_banner_image_3.png';
 import slide4 from '../../../Assets/Catering_banner_image_4.png';

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const slides = [
    { "image": slide1, "caption": "Business", "description": "Plan Your Next Event with Lalo's", "buttons":["Conferences","Meeting","Seminars","Product Launches"]},

    { "image": slide2, "caption": "Business", "description": "Plan Your Next Event with Lalo's", "buttons":["Inaugurations","Press Release","Exhibitions",]},
    { "image": slide3, "caption": "Private", "description": "Plan Your Next Event with Lalo's", "buttons":["Weddings","Birthdays","Anniversary","Baby Showers"]},
    { "image": slide4, "caption": "Private", "description": "Plan Your Next Event with Lalo's", "buttons":["House Warmings","At-home parties",]}
  
  ]

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
    }, 5000);
    return () => {
      clearInterval(slideInterval);
    };
  }, [currentSlide, slides.length]);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  // const btnGroup = (itemName) => {
  //   return(
  //     <div className='btn-resHero'>
        
  //     </div>
  //   )
  // }
  const BtnGroup = ({ itemText }) => {
    return <div className='btn-resHero'>
      <h1>{itemText}</h1>
    </div>;
  };

  return (
    <div className="hero-section">
        <div className='hero_wrap'>
            <div className="slideshow">
                {slides.map((slide, index) => (
                <div
                    className={`slide-image ${index === currentSlide ? "active" : ""}`}
                    key={index}
                    style={{ backgroundImage: `url(${slide.image})` }}
                >
                    <div className="caption">
                    <h1>{slide.description}</h1>
                        <p className='btn-biz' style={{ fontSize: isMobile ? "4vw" : "1.5vw" }}>{slide.caption}</p>
                        <div className='btn-group'>
                          {slide.buttons[0]? <p className='btn-item' style={{ fontSize: isMobile ? "5vw" : "3vw" }}>{slide.buttons[0]}</p> : ''}
                          {slide.buttons[1]? <p className='btn-item' style={{ fontSize: isMobile ? "5vw" : "3vw" }}>{slide.buttons[1]}</p> : ''}
                          {slide.buttons[2]? <p className='btn-item' style={{ fontSize: isMobile ? "5vw" : "3vw" }}>{slide.buttons[2]}</p> : ''}
                          {slide.buttons[3]? <p className='btn-item' style={{ fontSize: isMobile ? "5vw" : "3vw" }}>{slide.buttons[3]}</p> : ''}
                        </div>    
                    </div>
                </div>
                ))}
            </div>
            

            <div className="indicators-res">
                {slides.map((slide, index) => (
                <div
                    className={`indicator-res ${index === currentSlide ? "active-res" : ""}`}
                    key={index}
                    onClick={() => handleIndicatorClick(index)}
                />
                ))}
            </div>
        </div>
    </div>
  );
};

export default Hero;


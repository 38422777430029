import React,{useEffect} from 'react';
import './FeaturesTwo.css';
import { useInView } from 'react-intersection-observer';
import fea_back from '../../Assets/orange_back.svg'
import fea_one from '../../Assets/fea1.png'
import fea_two from '../../Assets/fea_two.svg'
import fea2_img from '../../Assets/fea2_img.png'
import fea_nn_two from '../../Assets/fea-nn-two.png'
import feaaa from '../../Assets/feaaa.png'
import feaaaM from '../../Assets/feaaaM.png'
import feaa_tru from '../../Assets/feaa-tru.png' 
import { useScroll, motion, useTransform, useMotionValueEvent, useAnimation  } from "framer-motion";
import { useState } from 'react';
import { Link } from 'react-router-dom';

const image1 = "https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
const image2 = "https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"

const Features = () => {
  const [oncerender, setOnceRender] = useState()
  const [isInView, setIsInView] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const [ref, inView] = useInView({
    threshold:0.3
  });

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);
  

  return (
    <div className="features-section-two" ref={ref}>

      {
        !isMobile &&
        <div className="feature-row-two">
        <div className="feature-content-two">
          <h2 className='feahead-two'>Lalo's on Wheels</h2>
          <p className='feapara-two'>Mex-cellent Flavors- Satisfaction Guaranteed!</p>
          <Link to="/hudson/contact-us" className='button_feaa'><button className='button_fea'>Book Now</button></Link>
        </div>
        <div className="feature-image-two" >
          <motion.img
 
          src={feaa_tru} alt="Feature 2"
          className='feature-image-twoc' 
          initial={{ x:-400, scale:5, }}
          animate={{ x: isInView ? -50 : -400 , scale: isInView ? 0.65 : 5 }}
          transition={{ duration:1.1, type:'spring' }}
          
          />
        </div>
      </div>

      }
      {
        isMobile && 
        <div className='fea2-m'>

          <div className='fea2-abv-m'>
          <img src={feaaaM} className='fea2-img-1'></img>
          </div>

          <div className='fea2-bel-m'>
            <h2 className='fea2head'>Lalo's on Wheels</h2>
            <p className='fea2para'>Mex-cellent Flavors- Satisfaction Guaranteed!</p>
            <Link to="/hudson/contact-us" className='button_feaa2'><button className='button_fea2'>Book Now</button></Link>
          </div>

        </div>
      }
     


    </div>
  );
};

export default Features;
